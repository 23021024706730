import React, { useEffect, useState } from 'react'
import { config, tabarray_post_audio } from '@/lib/config'
import getProfileByName from '@/lib/utils/admin/server/profile/getProfileByName'
import getUserOptional from '@/lib/utils/admin/getUserOptional'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'
import ProfileHomeContainer from '@/components/profile/profile-home-container'
import getMessage from '@/lib/utils/getMessage'
import isAdminUser from '@/lib/utils/isAdminUser'
import { getCacheDate } from '@/lib/utils/getCacheDate'
import PostArray from '@/components/profile/post/post-array'
import getRecordArraySwr from '@/lib/utils/admin/server/record/getRecordArraySwr'
import HeaderBar from '@/components/templateux/header-bar/header-bar'
import SvgJsx from '@/components/templateux/svg/svg-jsx'
import { hasData } from '@/lib/utils/hasData'
import { useRouter } from 'next/router'
import getScheduleByName from '@/lib/utils/admin/server/profile/getScheduleByName'
import useTimestamp from '@/lib/utils/hooks/useTimestamp'
import getServerSideSecurity from '@/lib/utils/admin/server/getServerSideSecurity'
import { getProfileUrl } from '@/lib/utils/getProfileUrl'
import Tabs from '@/components/templateux/tabs'
import { textProcess } from '@/lib/utils/textprocess'


export async function getServerSideProps(context) {

  //initial lookup to see if this is a spam referred visit 
  // and redirect to homepage if so rather than wasting database calls
  // look at if the username is too long or has forbidden characters (dashes), as indications
  if (context?.params?.user_name?.includes('-') || context?.params?.user_name?.length > config.max_username_length+10) {
    context.res.statusCode = 308;
    context.res.setHeader('Location', `${process.env.NEXT_PUBLIC_NODE_ENV=="development" 
                                      ? "http://" 
                                      : "https://"
                                      }${context.req.headers['host']}`);
    context.res.end();
  } else {
    context.res.setHeader(
      'Cache-Control',
      'public, s-maxage=300, stale-while-revalidate=300'
    )
  }

  //log the visit and return other relevant page / user data
  const {forbidden,user, ip, cookie, ssr_data_tribe} = await getServerSideSecurity(context)
  if (forbidden !== '') {context.res.write(forbidden); context.res.end();}

  const ssr_data_profile = 
      await getProfileByName(
            "profile" //table_name: string
          , "select_by_name" //path_name: string
          , context?.params?.user_name //user_name: string
          , user["id"].toString() //user_id: string
          , ssr_data_tribe?.tribe_id //tribe_id:string
          , ip //ip: string
          , cookie //cookie: string
          , `${context?.req?.headers?.host}${context?.resolvedUrl}` //url: string
        )

        
  const ssr_data_schedule = 
      await getScheduleByName(
        "user_schedule" //table_name: string
      , "select_by_user_name" //path_name: string
      , context?.params?.user_name //user_name: string
      , ssr_data_tribe?.tribe_id
    )

  const ssr_data_posts = 
      await getRecordArraySwr(
            "post" //table_name: string
          , "select_by_user_id" //path_name: string
          , ssr_data_profile?.id //user_id: string
          , "0" //my_id:string
          , ssr_data_tribe?.tribe_id //tribe_id:string
          , "1" //page: string
          , "RAND" //sort: string
          , config.itemdir //dir: string
          , '' //filter: string
      )
    



  const navtype = 'posts'
          
  return { 
      props: { 
         ssr_data_tribe: ssr_data_tribe
        ,ssr_data_profile: ssr_data_profile
        ,ssr_data_posts: ssr_data_posts
        ,ssr_data_schedule: ssr_data_schedule
        ,params: context?.params
        ,navtype: navtype
        ,helptype: 'content'
        ,navurl: getProfileUrl('',ssr_data_profile?.user_name,navtype,0,``)
        ,navtitle: `${ssr_data_profile?.user_name} | ${navtype}`
        ,nodata: getMessage("noresults",ssr_data_tribe)
      } 
      //,revalidate: 300, //86400 In seconds
    }
}

// export async function getStaticPaths() {

//   return {
//     paths: [],
//     fallback: 'blocking',
//   }
// }

function ProfileHomePage({  
                            ssr_data_tribe
                          , ssr_data_profile
                          , ssr_data_posts
                          , ssr_data_schedule
                          , params
                          , navtype 
                          , helptype
                          , navurl
                          , navtitle
                          , nodata
                        }) {


  //PRELOAD SSR DATA
  const router = useRouter()
  const { userdata, isUserDataLoading, isUserDataError } = useUserAuth()
  const [view_mode,set_view_mode] = useState(navtype)
  const [is_searching, set_is_searching] = useState(false)
  const [search_text,set_search_text] = useState('')
  const [filter_text,set_filter_text] = useState('')

  const {descriptionRef} = useTimestamp(ssr_data_posts)

  const [change_cache,set_change_cache] = useState(null)
  const [display_view,set_display_view] = useState(navtype)
  const [tab_options,set_tab_options] = useState(navtype)
  useEffect(()=>{set_display_view(navtype)},[navtype])

  useEffect(()=>{set_search_text(router?.query?.search?.toString())},[router])

  //slow routing from next.js query parsing

  const submitSearch = (e) => {
    e.preventDefault()
    set_filter_text(search_text)
    router.push({
      pathname: `/${ssr_data_profile?.user_name}`,
      query: { search: search_text}
    }, 
    undefined, { shallow: true }
    )
  }

  const clearSearch = (e) => {
    e.preventDefault()
    set_filter_text('')
    set_search_text('')
    router.push({
      pathname: `/${ssr_data_profile?.user_name}${navtype=="audio" ? "/audio" : ""}`,
      query: { }
    }, 
    undefined, { shallow: true }
    )
  }

  const [cache, set_cache] = useState(getCacheDate())
  const [isOpen,set_isOpen] = useState(false)

  const closeModal = () => {
      set_isOpen(false)
  }


      //Admin Lookup
      const [isAdmin,set_isAdmin] = useState(false)
      useEffect(()=>{
        set_isAdmin(isAdminUser( ssr_data_profile?.id            //user_id
                                ,ssr_data_profile?.bol_staff_help    //bol_staff_help
                                ,ssr_data_profile?.staff_json        //staff_json
                                ,ssr_data_profile?.bol_admin_help    //bol_admin_help
                                ,userdata?.id?.toString()            //my_id
                                ,userdata?.bol_admin?.toString()    //global_admin
                                ,helptype
                                ))
      },[
        ssr_data_profile
        ,userdata
        ,helptype
      ])


  const set_tab_header = (n,url) => {
    set_tab_options(n);
    router.push(url, undefined, { shallow: true });
  }


  return (<>
    <ProfileHomeContainer
        ssr_data_tribe={ssr_data_tribe}
        ssr_data_profile={ssr_data_profile}
        ssr_data_schedule={ssr_data_schedule}
        params={params}
        navtype={navtype}
        helptype={helptype}
        display_view={display_view}
        set_display_view={set_display_view}
        change_cache={change_cache}
        set_change_cache={set_change_cache}
    >

        
        <HeaderBar
          headertitle={
                          <div>
                          <Tabs
                              tabarray={tabarray_post_audio(ssr_data_profile?.user_name)}
                              clickval={tab_options}
                              set_clickval={set_tab_header}
                              tab_style="selectboxhome"
                            />
                        </div>
                        }
          show_rss={false}
          rss_link={`/${ssr_data_profile?.user_name?.toLowerCase()}/audio/podcast.rss`}
          rss_text={'rss'}
          action_button={
            <form
                          onSubmit={(e)=> submitSearch(e)}
                        >
                        <div className="flex text-sm font-base w-full group cursor-pointer">
                          <input 
                            className={`w-48 sm:w-64 transition-all duration-500 border rounded-l-md border-gray-700 group-hover:border-white bg-gray-700 placeholder-gray-500 text-white px-2
                              
                            `}
                            onChange={(e)=>{
                                //set_is_searching(e.target.value.length > 0)
                                set_search_text(e.target.value)
                              }}
                            onFocus={(e)=>{set_is_searching(true)}}
                            onBlur={(e)=>{set_is_searching(e.target.value.length > 0)}}
                            defaultValue={search_text}
                            placeholder={textProcess(`Search`)}
                          />
                          
                          <div 
                            className="border border-gray-700 group-hover:border-white  p-2 rounded-r-md"
                            onClick={(e)=> submitSearch(e)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                              submitSearch(e)
                            }}}
                          >
                            <SvgJsx 
                              type={'fill'}
                              icon={'search-sm'}
                              className={`h-5 w-5`}
                              title={'Search'}
                            />
                          </div>

                          {search_text?.length > 0 &&
                          <div 
                            className=" hover:text-white p-2 "
                            onClick={(e)=> clearSearch(e)}
                            onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                              clearSearch(e)
                            }}}
                            role="button"
                            
                            tabIndex={0}
                          >
                            <SvgJsx 
                              type={'fill'}
                              icon={'x-sm'}
                              className={`h-5 w-5`}
                              title={'Clear'}
                            />
                          </div>
                          }
                        </div>
                      </form>
          }
          show_action_button={true}
          show_border={true}
          show_bg={false}
          border_class={`border-y border-gray-700`}
        />
      
      <PostArray 
                    itemdata={ssr_data_posts}
                    isLoading={ false}
                    isError={ false }
                    userdata={userdata}
                    ownerdata={ssr_data_profile}
                    display_view={tab_options}
                    set_display_view={set_display_view}
                    cache={cache}
                    set_cache={set_cache}
                    slugfilter={''}
                    placeholderdata={[
                    //   {
                    //    post_title: 'Loading'
                    //   ,crdate: +new Date().toISOString()
                    //   ,start_date: +new Date().toISOString()
                    //   ,end_date: +new Date().toISOString()
                    //   ,post_contents_json: []
                    //   ,bol_status: -1
                    //   ,bol_public: 0
                    //   ,bol_recording: 0
                    //   ,user_id: profiledata?.id
                    //   ,user_name: 'Lurker'
                    //   ,avatar_url: ''
                    //   ,post_id: -1
                    // }
                    ]}
                    nodata={nodata}
                    navtype={navtype}
                    helptype={helptype}
                    isAdmin={isAdmin}
                    slug={''}
                    title={'All posts'}
                    swr_url={`/api/private/post/select?option=user_id&id=${ssr_data_profile?.id}&cache=${cache}${hasData(filter_text) ? `&q=tag:${filter_text}` : ''}`}
                    descriptionRef={descriptionRef}
                    ssr_data_tribe={ssr_data_tribe}   
                    origin_url={navurl}
                    origin_title={navtitle}  
                  />

    </ProfileHomeContainer>

    </>

      )


  }
  

  export default ProfileHomePage